import { UPDATE_DESIGN_TOKEN } from '../../actions/actionTypes';
import { IPrinterList } from '../../interfaces/Design/IPrinterList';
import { ITemplate } from '../../interfaces/Design/ITemplate';
import { ITemplateCreationResult } from '../../interfaces/Design/ITemplateCreationResult';
import { ITemplateExists } from '../../interfaces/Design/ITemplateExists';
import { ITemplateProperties } from '../../interfaces/Design/ITemplateProperties';
import Utils from '../../utilities/utils';
import DesignApi from '../prodDesignApi';
import UsersApi from '../prodUserApi';

export const getDesignToken = async(dispatch, profile, siteId, useSimpleToken = false) =>{
  // Make the request to refresh the token
  try {

    if (useSimpleToken) {
      const token = await UsersApi.getDesignApiToken(siteId);
      return token;
    }
    

    let userLanguageName = "English";
    let displayUnit = "Base";
    if (profile !== null) {
      const {Languages, LanguageId} = profile;
      userLanguageName = Languages.find(el => el.LanguageId === LanguageId)?.EnglishName;
      displayUnit = profile.DisplayUnit;
    }
    
    const utcOffset = Utils.UtcOffset().toString();
  
    const token = await UsersApi.getDesignApiToken(siteId, userLanguageName, utcOffset, displayUnit);
    // Save the new access token
    dispatch({ type: UPDATE_DESIGN_TOKEN, payload: token})
    return token;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    throw error; 
  }
}



export const getPrinterList = async (): Promise<IPrinterList> => {
  try {
    const response = await DesignApi.getPrinterList();
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const getAccountTemplatesList = async (printerName: string): Promise<ITemplateProperties[]> => {
  try {
    const response = await DesignApi.getAccountTemplatesList(printerName);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const getDefaultTemplatesList = async (printerName: string): Promise<ITemplateProperties[]> => {
  try {
    const response = await DesignApi.getDefaultTemplatesList(printerName);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const getBlankTemplate = async (printerName: string, printerFormat: string): Promise<string> => {
  try {
    const response = await DesignApi.getBlankTemplate(printerName, printerFormat);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const getAccountTemplates = async (printerName: string, templateNames: string[]): Promise<ITemplate[]> => {
  try {
    const response = await DesignApi.getTemplates(printerName, templateNames);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const getDefaultTemplates = async (printerName: string, templateNames: string[]): Promise<ITemplate[]> => {
  try {
    const response = await DesignApi.getDefaultTemplates(printerName, templateNames);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const checkTemplateExists = async (
  newName: string,
  printerName: string,
  printerFormat: string
): Promise<ITemplateExists> => {
  try {
    const response = await DesignApi.checkTemplateExists(newName, printerName, printerFormat);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const createNewTemplateFromBlank = async (
  newName: string,
  printerName: string,
  printerFormat: string
): Promise<ITemplateCreationResult> => {
  try {
    const response = await DesignApi.createNewTemplateFromBlank(newName, printerName, printerFormat);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const createNewTemplateFromFile = async (
  newName: string,
  fromTemplateName: string
): Promise<ITemplateCreationResult> => {
  try {
    const response = await DesignApi.createNewTemplateFromFile(newName, fromTemplateName);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const deleteTemplate = async (printerName: string, templateId: string): Promise<ITemplateCreationResult> => {
  try {
    const response = await DesignApi.deleteTemplate(printerName, templateId);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};
